import { map, uniqBy } from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import formatDate, { DateTypes } from '@/utils/format-date';

export const SIDEBAR_OPTIONS = {
  COLUMNS: 1,
  FILTERS: 2,
  HIGHLIGHTS: 3,
};

const createColumns = () => ({
  flag: {
    label: '',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
    nonResizable: true,
    minWidth: 130,
    maxWidth: 130,
    toggleable: false,
  },
  betId: {
    label: 'Bet ID',
    visible: true,
    order: 2,
    filterable: true,
    sortable: false,
    minWidth: 100,
  },
  competitionName: {
    label: 'Competition',
    visible: true,
    order: 3,
    filterable: true,
    sortable: true,
    minWidth: 150,
    maxWidth: 250,
  },
  eventName: {
    label: 'Event',
    visible: true,
    order: 4,
    filterable: true,
    sortable: false,
    minWidth: 230,
    maxWidth: 300,
  },
  market: {
    label: 'Market',
    visible: true,
    order: 5,
    filterable: true,
    sortable: false,
    minWidth: 170,
    maxWidth: 220,
  },
  selection: {
    label: 'Selection',
    visible: true,
    order: 6,
    filterable: true,
    sortable: false,
    minWidth: 120,
    maxWidth: 220,
  },
  odds: {
    label: 'Odds',
    visible: true,
    order: 7,
    filterable: true,
    sortable: false,
    minWidth: 90,
    maxWidth: 90,
  },
  date: {
    label: 'Date',
    visible: true,
    order: 8,
    inputType: 'date',
    defaultValue: () => formatDate(new Date(), DateTypes.ISO_DATE),
    filterable: true,
    sortable: false,
    minWidth: 140,
    maxWidth: 140,
  },
  time: {
    label: 'Time',
    visible: true,
    order: 9,
    filterable: true,
    sortable: false,
    minWidth: 120,
    maxWidth: 120,
  },
  strength: {
    label: 'Strength',
    visible: true,
    order: 10,
    filterable: true,
    sortable: false,
    minWidth: 80,
    maxWidth: 80,
  },
  betOutcome: {
    label: 'Settlement status',
    visible: false,
    order: 10,
    filterable: true,
    sortable: false,
    minWidth: 140,
    maxWidth: 80,
  },
  nickname: {
    label: 'Nickname',
    visible: false,
    order: 11,
    filterable: true,
    sortable: false,
    minWidth: 120,
    maxWidth: 120,
  },
  playerAccountId: {
    label: 'Account Id',
    visible: true,
    order: 12,
    filterable: false,
    sortable: false,
    minWidth: 170,
    maxWidth: 220,
  },
  /* name: {
    label: 'Name',
    visible: false,
    order: 11,
    filterable: true,
    sortable: false,
  }, */
  eventId: {
    label: 'Event ID',
    visible: false,
    order: 13,
    filterable: true,
    sortable: false,
  },
  client: {
    label: 'Client',
    visible: true,
    order: 14,
    filterable: true,
    sortable: false,
    minWidth: 80,
    maxWidth: 80,
  },
  stakeFactor: {
    label: 'Stake factor',
    visible: false,
    order: 15,
    filterable: true,
    sortable: false,
  },
  maxBetPercentage: {
    label: '% Max Bet',
    visible: false,
    order: 16,
    filterable: true,
    sortable: false,
  },
  /* autoMove: {
    label: 'Auto move',
    visible: false,
    order: 16,
    filterable: true,
    sortable: false,
  }, */
  stake: {
    label: 'Stake',
    visible: true,
    order: 17,
    filterable: true,
    sortable: false,
    minWidth: 120,
    maxWidth: 120,
  },
  freeStake: {
    label: 'Free stake',
    visible: false,
    order: 18,
    filterable: true,
    sortable: false,
    minWidth: 100,
    maxWidth: 100,
  },
  potentialPayout: {
    label: 'Potential payout',
    visible: true,
    order: 19,
    filterable: true,
    sortable: false,
    minWidth: 150,
  },
  /* limit: {
    label: 'Bet limit',
    visible: true,
    order: 18,
    filterable: true,
    sortable: false,
    minWidth: 100,
    maxWidth: 100,
  }, */
});

const formatDateTime = (dateTime) => {
  const formattedDate = format(zonedTimeToUtc(dateTime, 'UTC'), 'MM/dd/yyyy');
  const formattedTime = format(zonedTimeToUtc(dateTime, 'UTC'), 'hh:mm a');

  return {
    formattedDate,
    formattedTime,
  };
};

export const formatBetsData = (bets) => {
  const formatedBets = map(bets, (bet) => {
    const actionTime = bet.betActions?.[0]?.actionTime;
    const betParts = bet.betPartsByBetId.nodes;
    const betPartPrice = {
      decimalValue: bet.inferredDecimalPrice,
      probability: bet.inferredBetProbability,
      bias: 0,
      originalFormattedValue: bet.inferredOriginalFormattedPrice,
    };
    const isMultibet = betParts.length > 1;
    const numberOfDifferentEvents = uniqBy(betParts, 'event.eventName')?.length;
    const hasDifferentEvents = isMultibet && numberOfDifferentEvents !== 1;
    const marketCode = betParts[0]?.marketType?.marketCode;
    const selection = betParts[0]?.selection;
    const marketParams = betParts[0]?.marketType?.params;
    const isUsaView = betParts[0]?.event?.isUsaView;
    const eventName = isUsaView
      ? `${betParts[0]?.event?.eventName?.split(' v ')?.[1]} @ ${betParts[0]?.event?.eventName?.split(' v ')?.[0]}`
      : `${betParts[0]?.event?.eventName?.split(' v ')?.[0]} - ${betParts[0]?.event?.eventName?.split(' v ')?.[1]}`;
    const competitionName = betParts[0]?.event?.competition?.competitionName || '';
    const { formattedDate, formattedTime } = formatDateTime(actionTime);
    const betInfo = {
      date: formattedDate,
      time: formattedTime,
      acceptedBy: bet.betActions?.[0]?.agentId,
      client: bet.operatorId,
    };
    return ({
      ...betInfo,
      betId: bet.betId,
      stake: parseFloat(bet.betStake.amount / 100).toFixed(2),
      freeStake: parseFloat(bet.betStake.freeBetAmount / 100).toFixed(2),
      stakeFactor: parseFloat(bet.accountInfo?.stakeFactor * 100).toFixed(2),
      maxBetPercentage: parseFloat(bet.accountInfo?.maxBetPercentage * 100).toFixed(2),
      potentialPayout: parseFloat(bet.betPayout.max / 100).toFixed(2),
      betPartsCount: betParts.length,
      multibet: isMultibet,
      eventId: betParts[0]?.event?.eventId,
      eventName,
      hasDifferentEvents,
      competitionName,
      competitors: betParts[0]?.event?.competitors,
      sportId: betParts[0]?.event?.sportId,
      sport: betParts[0]?.event?.sport,
      isUsaView: betParts[0]?.event?.isUsaView,
      isFlagged: bet.isFlagged,
      hasExternalParts: bet.hasExternalParts,
      odds: betPartPrice,
      // eslint-disable-next-line no-nested-ternary
      market: isMultibet ? (numberOfDifferentEvents > 1 ? 'Parlay' : 'SGP') : marketCode,
      marketParams,
      marketSummary: betParts[0]?.marketSummary,
      limit: parseFloat(betParts[0]?.limit / 100).toFixed(2),
      selection,
      playerAccountId: bet.playerAccountId,
      strength: bet.accountInfo?.strength,
      nickname: bet.accountInfo?.nickname,
      betOutcome: bet.betOutcome,
      betParts: map(betParts, (betPart) => {
        const partMarketCode = betPart.marketType?.marketCode;
        const partSelection = betPart.selection;
        const partMarketParams = betPart.marketType?.params;
        const partIsUsaView = betPart.event?.isUsaView;
        const partEventName = partIsUsaView
          ? `${betPart.event?.eventName?.split(' v ')?.[1]} @ ${betPart.event?.eventName?.split(' v ')?.[0]}`
          : `${betPart.event?.eventName?.split(' v ')?.[0]} - ${betPart.event?.eventName?.split(' v ')?.[1]}`;
        return ({
          ...betInfo,
          betId: betPart.betId,
          eventId: betPart.event?.eventId,
          eventName: partEventName,
          competitionName: betPart.event?.competition?.competitionName || '',
          competitors: betPart.event?.competitors,
          sportId: betPart.event?.sportId,
          sport: betPart.event?.sport,
          isUsaView: betPart.event?.isUsaView,
          market: partMarketCode,
          marketParams: partMarketParams,
          marketSummary: betPart.marketSummary,
          limit: parseFloat(betPart.limit / 100).toFixed(2),
          selection: partSelection,
          odds: betPart.price,
          isBetPart: true,
          playerAccountId: bet.playerAccountId,
          betOutcome: betPart.betPartOutcome,
        });
      }),
    });
  });

  return formatedBets;
};

export const formatBetData = (bet) => {
  /* const actionTime = bet.betActions?.[0]?.actionTime; */
  const betParts = bet.parts;
  const betPartPrice = {
    decimalValue: bet.inferredDecimalPrice,
    probability: bet.inferredBetProbability,
    bias: 0,
    originalFormattedValue: bet.inferredOriginalFormattedPrice,
  };
  const isMultibet = betParts.length > 1;
  const numberOfDifferentEvents = uniqBy(betParts, 'huddleContentInfo.event.eventName')?.length;
  const hasDifferentEvents = isMultibet && numberOfDifferentEvents !== 1;
  const marketCode = betParts[0]?.huddleContentInfo?.market?.marketType?.marketCode;
  const selection = betParts[0]?.huddleContentInfo?.selection;
  const marketParams = betParts[0]?.huddleContentInfo?.market?.marketType?.params;
  const isUsaView = betParts[0]?.huddleContentInfo?.event?.isUSAView;
  const eventName = isUsaView
    ? `${betParts[0]?.huddleContentInfo?.event?.eventName?.split(' v ')?.[1]} @ ${betParts[0]?.huddleContentInfo?.event?.eventName?.split(' v ')?.[0]}`
    : `${betParts[0]?.huddleContentInfo?.event?.eventName?.split(' v ')?.[0]} - ${betParts[0]?.huddleContentInfo?.event?.eventName?.split(' v ')?.[1]}`;
  const competitionName = betParts[0]?.huddleContentInfo?.competition?.competitionName || '';
  const { formattedDate, formattedTime } = formatDateTime(bet.acceptanceTime);
  const betInfo = {
    date: formattedDate,
    time: formattedTime,
    acceptedBy: '-',
    client: bet.operatorId,
  };
  return ({
    ...betInfo,
    betId: bet.id,
    stake: parseFloat(bet.stake.amount / 100).toFixed(2),
    freeStake: parseFloat(bet.stake.freeBetAmount / 100).toFixed(2),
    potentialPayout: parseFloat(bet.payout.max / 100).toFixed(2),
    betPartsCount: betParts.length,
    multibet: isMultibet,
    eventId: betParts[0]?.huddleContentInfo?.event?.eventId,
    eventName,
    hasDifferentEvents,
    competitionName,
    competitors: betParts[0]?.huddleContentInfo?.event?.competitors,
    sportId: betParts[0]?.huddleContentInfo?.event?.sportId,
    sport: betParts[0]?.huddleContentInfo?.sport,
    isUsaView: betParts[0]?.huddleContentInfo?.event?.isUSAView,
    hasExternalParts: bet.hasExternalParts,
    odds: betPartPrice,
    // eslint-disable-next-line no-nested-ternary
    market: isMultibet ? (numberOfDifferentEvents > 1 ? 'Parlay' : 'SGP') : marketCode,
    marketParams,
    marketSummary: betParts[0]?.huddleContentInfo?.market?.marketSummary,
    limit: betParts[0]?.huddleContentInfo?.market?.limit,
    selection,
    playerAccountId: bet.externalPlayerId,
    strength: bet.accountInfo?.strength,
    nickname: bet.accountInfo?.nickname,
    stakeFactor: parseFloat(bet.accountInfo?.stakeFactor * 100).toFixed(2),
    maxBetPercentage: parseFloat(bet.accountInfo?.maxBetPercentage * 100).toFixed(2),
    highlightedRow: true, // highlight new bets so that traders know new bets arrived after last time they looked at bet-ticker
    betOutcome: bet.betOutcome,
    betParts: map(betParts, (betPart) => {
      const partMarketCode = betPart.huddleContentInfo?.market?.marketType?.marketCode;
      const partSelection = betPart.huddleContentInfo?.selection;
      const partMarketParams = betPart.huddleContentInfo?.market?.marketType?.params;
      const partIsUsaView = betPart.huddleContentInfo?.event?.isUSAView;
      const partEventName = partIsUsaView
        ? `${betPart.huddleContentInfo?.event?.eventName?.split(' v ')?.[1]} @ ${betPart.huddleContentInfo?.event?.eventName?.split(' v ')?.[0]}`
        : `${betPart.huddleContentInfo?.event?.eventName?.split(' v ')?.[0]} - ${betPart.huddleContentInfo?.event?.eventName?.split(' v ')?.[1]}`;
      return ({
        ...betInfo,
        betId: betPart.id,
        eventId: betPart.huddleContentInfo?.event?.eventId,
        eventName: partEventName,
        competitionName: betPart.huddleContentInfo?.competition?.competitionName || '',
        competitors: betPart.huddleContentInfo?.event?.competitors,
        sportId: betPart.huddleContentInfo?.event?.sportId,
        sport: betPart.huddleContentInfo?.sport,
        isUsaView: betPart.huddleContentInfo?.event?.isUSAView,
        market: partMarketCode,
        marketParams: partMarketParams,
        marketSummary: betPart.huddleContentInfo?.market?.marketSummary,
        limit: betPart.huddleContentInfo?.market?.limit,
        selection: partSelection,
        odds: betPart.huddleContentInfo?.selection?.price,
        isBetPart: true,
        playerAccountId: bet.externalPlayerId,
        betOutcome: betPart.betPartOutcome,
      });
    }),
  });
};

export default {
  createColumns,
};
